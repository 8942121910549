import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Get the Crisp app for all your devices.
Web
Login to app
Mac
Download
Windows
Download
iPhone
Download
Android
Download
Stay in touch with your customers, from your office or at the swimming pool. *`}</p>
    <ul>
      <li parentName="ul">{`Provided you are adventurous enough to take your Apple Watch underwater.
Ready to improve your customer engagement?`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      